











































/* eslint-disable @typescript-eslint/camelcase */

import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { Component, Vue, Watch } from 'vue-property-decorator'
import InputNumber from '@/components/Controls/InputNumber.vue'
import InputText from '@/components/Controls/InputText.vue'
import InputTextarea from '@/components/Controls/InputTextarea.vue'
import Calendar from '@/components/Controls/Calendar.vue'
import { componentsImports } from '../FormFields/index'

type Townhouse = {
  bedrooms: string | null;
  bathrooms: string | null;
  floorArea: number | null;
  airConditioning: string | null;
  secureCarParks: string | null;
  cityViews: string | null;
  waterViews: string | null;
  elevatedOutlook: string | null;
  pool: string | null;
  tennisCourt: string | null;
}

type ServerData = { [key: string]: string | number | null | string[] | object[] }

@Component({
  components: {
    ...componentsImports(),
    ValidationProvider,
    InputNumber,
    InputText,
    InputTextarea,
    Calendar
  }
})
export default class ResidentialTownhouseComplex extends Vue {
  formData () {
    const data: ServerData = {
      GeneralCondition: this.generalCondition,
      FloorConstruction: this.floorConstruction,
      WallConstruction: this.wallConstruction,
      RoofConstruction: this.roofConstruction,
      ApproximateBuildYear: this.approximateBuildYear,
      CarParks: this.carParks
    }

    data.Townhouses = this.townhouses.map(t => ({
      Bedrooms: t.bedrooms,
      Bathrooms: t.bathrooms,
      FloorArea: t.floorArea,
      AirConditioning: t.airConditioning,
      SecureParkingSpaces: t.secureCarParks,
      CityViews: t.cityViews,
      RiverViews: t.waterViews,
      ElevatedOutlook: t.elevatedOutlook,
      Pool: t.pool,
      TennisCourt: t.tennisCourt
    }))

    return data
  }

  loadData (data: any) {
    this.generalCondition = data.GeneralCondition
    this.floorConstruction = data.FloorConstruction
    this.wallConstruction = data.WallConstruction
    this.roofConstruction = data.RoofConstruction
    this.approximateBuildYear = data.ApproximateBuildYear
    this.carParks = data.CarParks

    if (!data.Townhouses) return

    data.Townhouses.forEach((t: any) => {
      this.townhouses.push({
        bedrooms: t.Bedrooms,
        bathrooms: t.Bathrooms,
        floorArea: t.FloorArea,
        airConditioning: t.AirConditioning,
        secureCarParks: t.SecureParkingSpaces,
        cityViews: t.CityViews,
        waterViews: t.RiverViews,
        elevatedOutlook: t.ElevatedOutlook,
        pool: t.Pool,
        tennisCourt: t.TennisCourt
      })
    })

    this.numberOfTownhouses = data.Townhouses.length
  }

  propertyType () {
    return 'ResidentialTownhouseComplex'
  }

  @Watch('numberOfTownhouses')
  townhousesChanged (newVal: number, oldVal: number | null) {
    if (newVal < 0) return
    if (oldVal === null) oldVal = 0
    if (newVal === this.townhouses.length) return

    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        this.townhouses.push(this.newTownhouse())
      }
    } else {
      this.townhouses = this.townhouses.slice(0, newVal)
    }
  }

  townhouses: Townhouse[] = []

  newTownhouse () {
    return {
      bedrooms: null,
      bathrooms: null,
      floorArea: null,
      airConditioning: null,
      secureCarParks: null,
      cityViews: null,
      waterViews: null,
      elevatedOutlook: null,
      pool: null,
      tennisCourt: null
    }
  }

  generalCondition = null
  approximateBuildYear = null
  floorArea = null
  carParks = null
  floorConstruction = null
  wallConstruction = null
  roofConstruction = null
  numberOfTownhouses = null
}
